
import Home from './pages/home.vue';

export const PrivateRoutes =
  [
    {
      path: '',
      component: Home,
      props: true,
      name: 'home-private',
    },
  ];
