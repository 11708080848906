<template>
	<div v-if="$router.currentRoute.name == 'home-widget' || $router.currentRoute.name == null" style="height: inherit;overflow-y: hidden;">
		<template v-if="is_button">
			<b-button @click="showModal = !showModal" variant="info">VER PLANO</b-button>
			<div class="modal-mask" v-if="showModal">
				<div class="modal-wrapper" @click="showModal = !showModal">
					<div class="modal-container" @click.stop>
						<div class="modal-content">
							<div class="modal-header">
								<slot name="header">
									<h5 class="modal-title text-center w-100">Plano interactivo</h5>
									<button type="button" aria-label="Close" @click="showModal = !showModal" class="close">×</button>
								</slot>
							</div>
							<div class="modal-body my-0 bg-light">
								<AssetMap :customer_id="customer_id" :project_id="project_id" :token="token" :types="types" :is_button="is_button" :currency="currency" :country="country" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<AssetMap :customer_id="customer_id" :project_id="project_id" :token="token" :types="types" :is_button="is_button" :currency="currency" :country="country" />
		</template>
	</div>
	<div v-else id="app">
		<template v-if="is_button">
			<b-button @click="showModal = !showModal" variant="info">VER PLANO</b-button>
			<div class="modal-mask" v-if="showModal">
				<div class="modal-wrapper" @click="showModal = !showModal">
					<div class="modal-container" @click.stop>
						<div class="modal-content">
							<div class="modal-header">
								<slot name="header">
									<h5 class="modal-title text-center w-100">Plano interactivo</h5>
									<button type="button" aria-label="Close" @click="showModal = !showModal" class="close">×</button>
								</slot>
							</div>
							<div class="modal-body my-0 bg-light">
								<router-view/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<router-view/>
		</template>
	</div>
</template>

<script>
import AssetMap from './components/asset-map.vue';
export default {
	name: 'App',
	components: {
		AssetMap
	},
	props:{
		customer_id: {default: null},
		project_id: {default: null},
		token: {default: null},
		types: {default: 'ESTACIONAMIENTO,BODEGA'},
		is_button: {default: false},
		country: {default: 'CL'},
		currency: {default: 'UF'}
	},
	data(){
		return{
			showModal: false
		}
	}
}
</script>

<style lang="scss">
	mobysuite-asset-map{
		height: inherit;
		overflow-y: hidden;
		--font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
		--font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
		font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
		@import 'node_modules/bootstrap/scss/bootstrap.scss';
		@import 'node_modules/bootstrap-vue/src/index.scss';
		.x-small {
			font-size: 70%;
			font-weight: 400;
		}
		.modal-mask {
			position: fixed;
			z-index: 9998;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, .5);
			display: table;
			transition: opacity .3s ease;
		}
		
		.modal-wrapper {
			display: table-cell;
			vertical-align: middle;
		}
		.modal-header{
			padding: 0.2rem 1rem;
		}
		.modal-container {
			width: 90%;
			max-width: 1300px;
			margin: 0px auto;
			background-color: #fff;
			border-radius: 20px;
			box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
			transition: all .3s ease;
			font-family: Helvetica, Arial, sans-serif;
		}
		.modal-header h3 {
			margin-top: 0;
			color: #42b983;
		}
		.modal-default-button {
			float: right;
		}
		.modal-enter {
			opacity: 0;
		}
		.modal-leave-active {
			opacity: 0;
		}
		.modal-enter .modal-container,
		.modal-leave-active .modal-container {
			-webkit-transform: scale(1.1);
			transform: scale(1.1);
		}
		.modal-title {
			margin-top: 0px;
			margin-bottom: 0;
			line-height: 1.5;
			font-weight: 400;
			font-size: 25px;
		}
		.button-control{
			overflow-anchor: none;
			position: absolute;
			right: 0;
			top: 10px;
			z-index: 1;
			opacity: .8;
		}
	}
	@media print {
		body *, .no-print {
			visibility: hidden;
		}
		#asset-image, #asset-image * {
			visibility: visible;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
</style>
