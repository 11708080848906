import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vueCustomElement from "vue-custom-element";
import "document-register-element/build/document-register-element";
import VueAxios from "vue-axios";
import axios from "axios";
import Vuex from "vuex";
import BootstrapVue from "bootstrap-vue/dist/bootstrap-vue.esm";
import InlineSvg from "vue-inline-svg";
import Panzoom from "@panzoom/panzoom";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  params: {},
});

Vue.use(BootstrapVue);
Vue.use(VueAxios, instance);
Vue.use(vueCustomElement);
Vue.use(Vuex);
Vue.component("inline-svg", InlineSvg);
Object.defineProperty(Vue.prototype, "$panzoom", { value: Panzoom });

const store = new Vuex.Store({
  state: {
    images: null,
    assets: null,
    loading: false,
  },
  mutations: {
    async setAssets(state, data) {
      state.loading = true;
      let assets = [];
      setAssetData(state, data, assets);
    },
  },
  actions: {
    setAssets(context, data) {
      context.commit("setAssets", data);
    },
  },
  getters: {
    getAssets(state) {
      return state.assets;
    },
    getAsset: (state) => (id) => {
      let asset = state.assets.find((asset) => asset.ID_BIEN == id);
      return asset
        ? asset
        : state.assets.find((asset) => asset.TIPO_BIEN + asset.NUM_BIEN == id);
    },
    getImages(state) {
      return state.images;
    },
  },
});
function setAssetData(state, data, assets) {
  instance
    .get("/assets", { params: data })
    .then((response) => {
      if (response.data.assets.length == 0) {
        state.images = response.data.images;
        state.assets = assets;
        state.loading = false;
        return { assets: assets, images: response.data.images };
      } else {
        assets.push(...response.data.assets);
        data.page += 1;
        setAssetData(state, data, assets);
      }
    })
    .catch(() => {
      setAssetData(state, data, assets);
    });
}
Vue.config.productionTip = false;
Vue.router = router;
App.router = Vue.router;
App.store = store;
Vue.customElement("mobysuite-asset-map", App);
