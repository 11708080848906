
import Home from './pages/home.vue';

export const PublicRoutes =
  [
    {
      path: '',
      component: Home,
      props: true,
      name: 'home-widget',
    },
    {
      path: '/:customer_id/proyecto/:project_id/token/:token',
      component: Home,
      props: true,
      name: 'home',
    },
    {
      path: '/:customer_id/proyecto/:project_id/token/:token/types/:types',
      component: Home,
      props: true,
      name: 'home-filtered',
    }

  ];
