<template>
	<div>
        <h1>Private</h1>
	</div>
</template>

<script>
export default {
    name: 'home-private',
    props:{
	},
	methods: {
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
