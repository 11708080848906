<template>
	<AssetMap :customer_id="customer_id" :project_id="project_id" :token="token" :types="types" :is_button="is_button" />
</template>

<script>
import AssetMap from '../../../components/asset-map.vue';
export default {
	name: 'home-public',
	props:{
		customer_id: {default: null},
		project_id: {default: null},
		token: {default: null},
		types: {default: 'ESTACIONAMIENTO,BODEGA'},
		is_button: {default: false}
	},
	components: {
		AssetMap
	}
}
</script>
