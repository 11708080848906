<template>
  <b-overlay :show="$store.state.loading" rounded="sm">
    <div style="position: relative; min-height: 200px">
      <b-sidebar
        id="sidebar-filter"
        title="Controles"
        shadow
        :right="true"
        class="no-print"
      >
        <b-card>
          <div class="text-right" v-if="false">
            <b-button
              class="mb-2"
              size="sm"
              variant="info"
              @click="initStructure(0)"
              >Sincronizar datos</b-button
            >
          </div>
          <h5>Seleccionar piso</h5>
          <b-form-select
            class="my-2"
            v-model="selected"
            :options="images"
            @input="changeImage()"
          ></b-form-select>
          <div class="text-right">
            <b-button
              v-if="selected"
              @click="print()"
              squared
              block
              size="sm"
              variant="success"
              class="my-2 text-uppercase"
              >IMPRIMIR</b-button
            >
            <b-button
              v-if="selected"
              :href="assetImage"
              target="_blank"
              squared
              size="sm"
              variant="info"
              class="my-2 text-uppercase"
              >VER {{ images.find((image) => image.value == selected).text }} EN
              BLANCO</b-button
            >
          </div>
          <div class="accordion" role="tablist">
            <b-card no-body>
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle.accordion-zoom
                  variant="light"
                  size="sm"
                  >ZOOM</b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-zoom"
                visible
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <div class="mb-4">
                    <b-button-group class="w-100" size="sm">
                      <b-button variant="info" id="zoom-out">- ZOOM</b-button>
                      <b-button variant="info" id="zoom-in">+ ZOOM</b-button>
                    </b-button-group>
                  </div>
                  <div class="text-center">
                    <b-button
                      size="sm"
                      variant="danger"
                      class="px-5"
                      id="zoom-reset"
                      >REINICIAR</b-button
                    >
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle.accordion-filter
                  variant="light"
                  size="sm"
                  >FILTROS</b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-filter"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body class="small align-items-center">
                  <label>Ver tipología</label>
                  <b-form-select
                    class="mt-2 mb-4"
                    size="sm"
                    v-model="filter.type"
                    :options="assetTypes"
                    @input="showAddedAssets()"
                  >
                    <b-form-select-option :value="null"
                      >Todos</b-form-select-option
                    >
                  </b-form-select>
                  <b-form-checkbox
                    switch
                    class="my-2"
                    v-model="filter.showAddedAssets"
                    @input="showAddedAssets()"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Ver bienes conjuntos
                  </b-form-checkbox>
                  <b-form-checkbox
                    switch
                    class="my-2"
                    v-model="filter.showBlocked"
                    :value="true"
                    :unchecked-value="false"
                    @input="paintBlocked()"
                  >
                    Ver bienes bloqueados
                  </b-form-checkbox>
                  <b-form-checkbox
                    switch
                    class="my-2"
                    v-model="filter.clickableUnavailable"
                    :value="true"
                    :unchecked-value="false"
                    @input="cleanUnavailable()"
                  >
                    No disponibles seleccionables
                  </b-form-checkbox>
                  <b-form-checkbox
                    switch
                    class="my-2"
                    v-model="filter.showDetail"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Ver detalle del bien
                  </b-form-checkbox>
                  <b-button
                    block
                    size="sm"
                    v-b-toggle.collapse-simbology
                    variant="info"
                  >
                    Pintar por estado
                  </b-button>
                  <b-collapse id="collapse-simbology">
                    <b-list-group>
                      <b-list-group-item class="d-flex align-items-center py-1">
                        <b-button
                          :variant="filter.showAllColors ? 'danger' : 'success'"
                          size="sm"
                          block
                          squared
                          @click="setAllColors()"
                          >{{
                            filter.showAllColors ? "QUITAR TODOS" : "VER TODOS"
                          }}</b-button
                        >
                      </b-list-group-item>
                      <b-list-group-item
                        v-for="(asset, key) in assetStates"
                        v-bind:key="'assetState-' + key"
                        class="d-flex align-items-center py-1"
                      >
                        <b-form-checkbox
                          switch
                          v-model="filter.showColors[asset.value]"
                          :value="true"
                          :unchecked-value="false"
                          @input="setFilter()"
                        >
                          <b-avatar
                            size="sm"
                            text=" "
                            class="mr-3"
                            :style="{
                              backgroundColor: asset.color,
                              opacity: 0.7,
                              width: '20px',
                              height: '20px',
                            }"
                          ></b-avatar>
                          <span class="mr-auto x-small">{{ asset.text }}</span>
                        </b-form-checkbox>
                      </b-list-group-item>
                    </b-list-group>
                  </b-collapse>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-card>
      </b-sidebar>
      <b-container id="asset-map" fluid>
        <b-row>
          <b-col cols="12" style="overflow: hidden; position: relative">
            <b-button
              v-b-toggle.sidebar-filter
              squared
              variant="info"
              class="float-right mt-3 button-control no-print"
              >CONTROLES</b-button
            >
            <div id="asset-container">
              <inline-svg
                id="asset-image"
                v-if="assetImage"
                :src="assetImage"
                @loaded="setSvgTriggers()"
              />
            </div>
          </b-col>
          <b-alert
            show
            v-if="asset"
            variant="light"
            fade
            style="position: fixed; bottom: 0; z-index:	100;"
            class="shadow no-print"
          >
            <h5>{{ asset.TIPO_BIEN }} {{ asset.NUM_BIEN }}</h5>
            <b-table-simple>
              <b-tr>
                <b-th>
                  Estado:
                </b-th>
                <b-td class="text-right">
                  {{ setState(asset.ESTADO_BIEN) }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Bloqueado:
                </b-th>
                <b-td class="text-right">
                  {{ asset.DISPONIBLE ? "No" : "Sí" }}
                </b-td>
              </b-tr>
              <template v-if="filter.showDetail">
                <b-tr>
                  <b-th>
                    Precio lista:
                  </b-th>
                  <b-td class="text-right">
                    {{ setNumber(asset.VALOR) }} {{ currency }}
                  </b-td>
                </b-tr>
                <b-tr v-if="asset.TIPO.length > 0">
                  <b-th>
                    Tipología:
                  </b-th>
                  <b-td class="text-right">
                    {{ asset.TIPO }}
                  </b-td>
                </b-tr>
                <b-tr v-if="asset.SUP_INTERIOR_MUNICIPAL > 0">
                  <b-th>
                    Superficie municipal:
                  </b-th>
                  <b-td class="text-right">
                    {{ setNumber(asset.SUP_INTERIOR_MUNICIPAL) }}m<sup>2</sup>
                  </b-td>
                </b-tr>
                <b-tr v-if="asset.SUP_TOTAL > 0">
                  <b-th>
                    Superficie total:
                  </b-th>
                  <b-td class="text-right">
                    {{ setNumber(asset.SUP_TOTAL) }}m<sup>2</sup>
                  </b-td>
                </b-tr>
                <template v-if="asset.CLIENT_NAME">
                  <b-tr>
                    <b-th>
                      Nombre cliente:
                    </b-th>
                    <b-td class="text-right">
                      {{ asset.CLIENT_NAME }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th> {{ country == "CL" ? "Rut" : "Dni" }} cliente </b-th>
                    <b-td class="text-right">
                      {{ asset.CLIENT_RUT }}
                    </b-td>
                  </b-tr>
                </template>
              </template>
              <template v-if="addedAssets.length > 0">
                <b-tr>
                  <b-td colspan="2"><h6>Bienes asociados</h6></b-td>
                </b-tr>
                <b-tr
                  v-for="(addedAsset, key) in addedAssets"
                  v-bind:key="'addedAsset-' + key"
                >
                  <b-td colspan="2">
                    {{ addedAsset }}
                  </b-td>
                </b-tr>
              </template>
            </b-table-simple>
          </b-alert>
        </b-row>
      </b-container>
    </div>
  </b-overlay>
</template>

<script>
export default {
  name: "home-public",
  props: {
    customer_id: { default: null },
    project_id: { default: null },
    token: { default: null },
    types: { default: null },
    is_button: { default: null },
    country: { default: "CL" },
    currency: { default: "UF" },
  },
  data() {
    return {
      clickedElement: null,
      assets: null,
      showSvg: false,
      assetImage: null,
      assetTypes: [],
      images: [],
      assetStates: [
        { text: "DISPONIBLE", color: "rgb(0, 128, 1)", value: "DISPONIBLE" },
        {
          text: this.country == "CL" ? "RESERVADO" : "SEPARADO",
          color: "rgb(0, 123, 255)",
          value: "RESERVADO",
        },
        {
          text: this.country == "CL" ? "PROMESADO" : "MINUTADO",
          color: "rgb(255, 165, 0)",
          value: "PROMESADO",
        },
        { text: "ESCRITURADO", color: "rgb(255, 2, 0)", value: "ESCRITURADO" },
        { text: "POSTVENTA", color: "rgb(119, 136, 153)", value: "POSTVENTA" },
        { text: "ENTREGADO", color: "rgb(91, 34, 197)", value: "ENTREGADO" },
      ],
      selected: null,
      asset: null,
      addedAssets: [],
      filter: {
        showAllColors: false,
        type: null,
        showAddedAssets: false,
        showColors: {
          DISPONIBLE: false,
          RESERVADO: false,
          PROMESADO: false,
          ESCRITURADO: false,
          POSTVENTA: false,
          ENTREGADO: false,
        },
        clickableUnavailable: false,
        showDetail: false,
        showBlocked: false,
      },
    };
  },
  mounted() {
    this.initStructure(0);
  },
  methods: {
    initStructure(page = 0) {
      this.$store.commit("setAssets", {
        customer_id: this.customer_id,
        project_id: this.project_id,
        types: this.types,
        token: this.token,
        page: page,
      });
    },
    changeImage() {
      this.assetImage = this.selected;
      this.clickedElement = null;
    },
    print() {
      window.print();
    },
    setZoomControls(svgDoc) {
      if (svgDoc) {
        const panzoom = this.$panzoom(svgDoc, {
          maxScale: 10,
        });
        document
          .getElementById("zoom-in")
          .addEventListener("click", panzoom.zoomIn);
        document
          .getElementById("zoom-out")
          .addEventListener("click", panzoom.zoomOut);
        document
          .getElementById("zoom-reset")
          .addEventListener("click", panzoom.reset);
        svgDoc.parentElement.addEventListener("wheel", function(event) {
          panzoom.zoomWithWheel(event);
        });
      }
    },
    setSvgTriggers() {
      if (this.assets) {
        let self = this;
        self.showSvg = true;
        let svgDoc = document.getElementById("asset-image");
        this.setZoomControls(svgDoc);
        let elements =
          svgDoc.querySelectorAll("#asset-elements g").length == 0
            ? svgDoc.querySelector("#asset-elements").children
            : svgDoc.querySelectorAll("#asset-elements g");
        let assetElement = null;
        elements.forEach(function(asset) {
          asset.id =
            asset.getAttribute("serif:id") || asset.id.replace("_", "");
          assetElement = self.$store.getters.getAsset(asset.id);
          if (assetElement) {
            asset.style.cursor =
              assetElement.ESTADO_BIEN != "DISPONIBLE" ||
              assetElement.DISPONIBLE == false
                ? "not-allowed"
                : "pointer";
            let path =
              typeof asset.getElementsByTagName("rect")[0] == "undefined"
                ? asset.getElementsByTagName("path")[0]
                : asset.getElementsByTagName("rect")[0];
            path = typeof path == "undefined" ? asset : path;
            path.setAttribute(
              "data-color",
              self.setColor(assetElement.ESTADO_BIEN)
            );
            path.setAttribute("data-state", assetElement.ESTADO_BIEN);
            path.setAttribute("data-type", assetElement.TIPO);
            path.setAttribute("data-available", assetElement.DISPONIBLE);
            if (assetElement.BIENES_CONJUNTOS) {
              path.setAttribute("data-added", assetElement.BIENES_CONJUNTOS);
            }
            path.style.fillOpacity = ".5";
            path.style.stroke = "transparent";
            path.style.strokeWidth = "3px";
            asset.addEventListener(
              "click",
              function() {
                self.addedAssets = [];
                self.paintElements();
                let path =
                  typeof asset.getElementsByTagName("rect")[0] == "undefined"
                    ? asset.getElementsByTagName("path")[0]
                    : asset.getElementsByTagName("rect")[0];
                path = typeof path == "undefined" ? asset : path;
                if (
                  self.filter.clickableUnavailable ||
                  path.getAttribute("data-state") == "DISPONIBLE"
                ) {
                  if (
                    path.getAttribute("data-available") == "true" ||
                    (self.filter.showBlocked &&
                      self.filter.clickableUnavailable)
                  ) {
                    self.clickedElement = this.id;
                    path.style.fill = "rgb(173, 216, 230)";
                    let assetObject = self.$store.getters.getAsset(asset.id);
                    if (assetObject.BIENES_CONJUNTOS) {
                      assetObject.BIENES_CONJUNTOS.split(",").forEach(function(
                        bien_conjuto
                      ) {
                        let addedAsset = self.$store.getters.getAsset(
                          bien_conjuto
                        );
                        let paintedElement = document.getElementById(
                          bien_conjuto
                        );
                        if (paintedElement) {
                          self.addedAssets.push(
                            addedAsset.TIPO_BIEN + " " + addedAsset.NUM_BIEN
                          );
                          let path =
                            typeof paintedElement.getElementsByTagName(
                              "rect"
                            )[0] == "undefined"
                              ? paintedElement.getElementsByTagName("path")[0]
                              : paintedElement.getElementsByTagName("rect")[0];
                          path.style.fill = "rgb(173, 216, 230)";
                        }
                      });
                    }
                    if (assetObject.TEXTO_BIENES_CONJUNTOS) {
                      self.addedAssets = [];
                      assetObject.TEXTO_BIENES_CONJUNTOS.split(",").forEach(
                        function(bien_conjuto) {
                          self.addedAssets.push(bien_conjuto);
                        }
                      );
                    }
                  }
                }
              },
              false
            );
          }
        }, false);
        self.setFilter();
      }
    },
    showAddedAssets() {
      let self = this;
      let svgDoc = document.getElementById("asset-image");
      let elements =
        svgDoc.querySelectorAll("#asset-elements g").length == 0
          ? svgDoc.querySelector("#asset-elements").children
          : svgDoc.querySelectorAll("#asset-elements g");
      elements.forEach(function(asset) {
        let path =
          typeof asset.getElementsByTagName("rect")[0] == "undefined"
            ? asset.getElementsByTagName("path")[0]
            : asset.getElementsByTagName("rect")[0];
        path = typeof path == "undefined" ? asset : path;
        path.style.stroke = "transparent";
        if (self.filter.showAddedAssets && path.getAttribute("data-added")) {
          path.style.stroke = "rgb(255, 165, 0)";
        } else if (
          self.filter.showBlocked &&
          path.getAttribute("data-available") == "false"
        ) {
          path.style.stroke = "rgb(255, 0, 0)";
        } else if (
          self.filter.type &&
          path.getAttribute("data-type") == self.filter.type
        ) {
          path.style.stroke = "rgb(92, 201, 245)";
        }
      }, false);
    },
    setAllColors() {
      this.filter.showAllColors = !this.filter.showAllColors;
      if (this.filter.showAllColors) {
        for (let color in this.filter.showColors) {
          this.filter.showColors[color] = true;
        }
        this.paintElements();
      } else {
        for (let color in this.filter.showColors) {
          this.filter.showColors[color] = false;
        }
        this.paintElements();
      }
    },
    setFilter() {
      for (let color in this.filter.showColors) {
        if (!this.filter.showColors[color]) this.filter.showAllColors = false;
      }
      if (this.filter.showAddedAssets) {
        this.showAddedAssets();
      }
      this.paintElements();
    },
    paintElements() {
      let self = this;
      let svgDoc = document.getElementById("asset-image");
      let elements =
        svgDoc.querySelectorAll("#asset-elements g").length == 0
          ? svgDoc.querySelector("#asset-elements").children
          : svgDoc.querySelectorAll("#asset-elements g");
      elements.forEach(function(asset) {
        let path =
          typeof asset.getElementsByTagName("rect")[0] == "undefined"
            ? asset.getElementsByTagName("path")[0]
            : asset.getElementsByTagName("rect")[0];
        path = typeof path == "undefined" ? asset : path;
        let assetObject = self.$store.getters.getAsset(asset.id);
        if (assetObject) {
          if (self.filter.showColors[assetObject.ESTADO_BIEN]) {
            path.style.fill = self.setColor(path.getAttribute("data-state"));
          } else {
            path.style.fill = "transparent";
          }
        }
      }, false);
    },
    cleanUnavailable() {
      if (this.asset) {
        if (this.asset.ESTADO_BIEN != "DISPONIBLE") {
          this.clickedElement = null;
          this.paintElements();
        }
      }
    },
    paintBlocked() {
      let self = this;
      let svgDoc = document.getElementById("asset-image");
      let elements =
        svgDoc.querySelectorAll("#asset-elements g").length == 0
          ? svgDoc.querySelector("#asset-elements").children
          : svgDoc.querySelectorAll("#asset-elements g");
      elements.forEach(function(asset) {
        let path =
          typeof asset.getElementsByTagName("rect")[0] == "undefined"
            ? asset.getElementsByTagName("path")[0]
            : asset.getElementsByTagName("rect")[0];
        path = typeof path == "undefined" ? asset : path;
        path.style.stroke = "transparent";
        if (
          self.filter.showBlocked &&
          path.getAttribute("data-available") == "false"
        ) {
          path.style.stroke = "rgb(255, 0, 0)";
        } else if (
          self.filter.showAddedAssets &&
          path.getAttribute("data-added")
        ) {
          path.style.stroke = "rgb(255, 165, 0)";
        } else if (
          self.filter.type &&
          path.getAttribute("data-type") == self.filter.type
        ) {
          path.style.stroke = "rgb(92, 201, 245)";
        }
      }, false);
      this.paintElements();
    },
    setState(state) {
      let res = state;
      if (this.country == "PE") {
        switch (state) {
          case "RESERVADO":
            res = "SEPARADO";
            break;
          case "PROMESADO":
            res = "MINUTADO";
            break;
        }
      }
      return res;
    },
    setColor(assetState) {
      switch (assetState) {
        case "DISPONIBLE":
          return "rgb(0, 128, 1)";
        case "RESERVADO":
          return "rgb(0, 123, 255)";
        case "PROMESADO":
          return "rgb(255, 165, 0)";
        case "ESCRITURADO":
          return "rgb(255, 2, 0)";
        case "POSTVENTA":
          return "rgb(119, 136, 153)";
        case "ENTREGADO":
          return "rgb(91, 34, 197)";
        default:
          return "";
      }
    },
    setNumber(element) {
      return Number(element).toLocaleString("es", { maximumFractionDigits: 2 });
    },
  },
  watch: {
    clickedElement() {
      this.asset = this.$store.getters.getAsset(this.clickedElement);
    },
    "$store.state.loading": {
      handler: function() {
        if (!this.$store.state.loading) {
          this.assets = this.$store.getters.getAssets;
          this.images = this.$store.getters.getImages;
          this.assetTypes = this.assets
            .map((obj) => obj.TIPO)
            .filter((elem, pos, arr) => arr.indexOf(elem) == pos)
            .sort();
          if (this.images.length !== null) {
            if (this.selected === null) {
              this.selected = this.images[0].value;
            }
            this.changeImage();
          }
        }
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#asset-container {
  border: 1px solid #eee;
  margin-left: auto;
  margin-right: auto;
}
</style>
